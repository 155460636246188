/** React/Utils */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/index';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

/** Local */
import OrderContext from '../../../../store/contexts/orderContext';
import translations from './emails.i18n';
import useSnacks from '../../../../hooks/useSnacks';
import BasicTableRow from '../../../shared/table/tableRow';
import OMA_NOTIFICATION_SUMMARY_QUERY from '../../../../queries/notificationSummaryOMA.query';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import { transformEmailData } from '../../../../utils/email';
import { transformSmsData } from '../../../../utils/sms';
import { useHistoryPushWithSessionId } from '../../../../hooks/useHistorySessionId';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';

/**
 * Main react component housing the emails tab
 */
const Emails = ({ paramOrderId, match }) => {
  const classes = useStyles();
  const [orderState, setOrderState] = useContext(OrderContext);
  const { setError } = useSnacks();
  const location = useLocation();
  const setRoute = useHistoryPushWithSessionId();
  const [orderComs, setOrderComs] = useState(null);

  const isKorea = orderState?.omsRegionReference === 'NIKEKR';
  const comChannel = isKorea ? 'sms' : 'email';
  /**
   * if Emails is rendered from Order we'll have an orderId passed as param
   * otherwise we need the standalone orderId. we can grab that orderId
   * from the url params CSP sends
   */
  const isStandAlone = !Boolean(paramOrderId);
  const orderId = isStandAlone ? match?.params?.orderId : paramOrderId;
  const createdDate = orderState?.orderCreateDate;

  const {
    ARIA_EMAILS,
    ARIA_EMAIL_LOADING,
    CATEGORY,
    DATE,
    COMMUNICATIONS,
    NO_EMAILS,
    ORDER_EMAILS_ERROR,
    RESEND,
    SENT_TO,
    TYPE,
    MESSAGE,
  } = useMemoTranslations(translations);

  const [fetchOrderComs, { data, loading: orderComsLoading, error }] = useLazyQuery(
    OMA_NOTIFICATION_SUMMARY_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        if (!error?.message?.includes('404')) {
          console.error('Order Emails:', error);
          setError(`${ORDER_EMAILS_ERROR}: ${error.message}`);
        } else {
          setOrderComs([]);
        }
      },
      onCompleted: () => {
        const coms = data?.orderNotificationSummaryOMA?.results;
        setOrderComs(coms);
      },
    }
  );

  // Queries order details
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, loading: orderDetailLoading, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: () => {
      setError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      const { orderDetail } = queryOrderDetailsData;
      setOrderState(orderDetail);
    },
  });

  const viewComDetails = (sendId) => {
    setRoute(`/order/${orderId}/emails/${sendId}/${!isStandAlone ? 'dependant' : 'standalone'}`);
  };

  useEffect(() => {
    // Will call in OMOBO once orderState has loaded
    if (location.pathname.includes('/emails') && createdDate && !orderComs) {
      fetchOrderComs({
        variables: {
          orderNumber: orderId,
          orderCreated: createdDate,
          channel: comChannel,
        },
      });
    }
  }, [location.pathname, createdDate]);

  useEffect(() => {
    // Will check and run only when loaded into from CSP (no orderState available)
    if (
      location.pathname.includes('/emails') &&
      isStandAlone &&
      !orderComs &&
      orderState?.omsRegionReference
    ) {
      fetchOrderComs({
        variables: { orderNumber: orderId, orderCreated: createdDate, channel: comChannel },
      });
    }
    if (isStandAlone && !orderState?.omsRegionReference) {
      queryOrderDetails({
        variables: {
          orderNumber: orderId,
        },
      });
    }
  }, [location.pathname, orderState]);

  // TODO only display category once SMS sends something other than none
  const comsHeaders = [DATE, SENT_TO, isKorea ? MESSAGE : CATEGORY, TYPE];

  return (
    <Card className={classes.cardSpacing} elevation={3}>
      <CardContent className={classes.cardContent}>
        <Toolbar variant={'dense'} className={classes.toolbar} disableGutters={true}>
          <CardHeader
            disableTypography
            classes={{ root: classes.cardHeaderRoot }}
            title={<h1 className={classes.cardHeading}>{COMMUNICATIONS}</h1>}
          />
        </Toolbar>
        {(orderDetailLoading || orderComsLoading) && (
          <CircularProgress aria-label={ARIA_EMAIL_LOADING} />
        )}
        {!(orderDetailLoading || orderComsLoading) && Array.isArray(orderComs) && (
          <Table aria-label={ARIA_EMAILS} data-testid={'emails-table'}>
            <TableBody>
              {orderComs.length > 0 ? (
                <BasicTableRow header data={comsHeaders} cellClassName={classes.tableHeader} />
              ) : (
                <BasicTableRow
                  data={[
                    <Typography
                      variant='subtitle1'
                      id='noEmails'
                      className={classes.noResults}
                      data-testid={'no-results-message'}>
                      {NO_EMAILS}
                    </Typography>,
                  ]}
                  cellClassName={classes.tableHeader}
                />
              )}
              {orderComs.map((com, i) => (
                <BasicTableRow
                  key={i}
                  testId={`email-${i}`}
                  data={
                    comChannel === 'sms'
                      ? transformSmsData(com, RESEND)
                      : transformEmailData(com, RESEND)
                  }
                  rowClassName={classes.tableRow}
                  action={() => viewComDetails(orderComs[i].send_id)}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

Emails.propTypes = {
  paramOrderId: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  cardHeaderRoot: {
    paddingLeft: theme.spacing(1),
  },
  cardHeading: {
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: 0,
  },
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  noResults: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tableRow: {
    '&:hover': {
      background: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
  tableHeader: {
    margin: 0,
    paddingTop: 0,
    padding: theme.spacing(1.2),
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

export default Emails;
