const translations = {
  ADD_ITEM_DETAILS: {
    description: 'label on the Price Mod Dialog for "Add Item Details"',
    primaryValue: 'Add Item Details',
    stringKey: 'addItemDetails',
  },
  EXIT: {
    description: 'label on the Price Mods Dialog for "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  PRICE_DISCOUNT_REASON: {
    description: 'label on the Price Mod Dialog for "Price Discount Reason"',
    primaryValue: 'Select a Price Modification Reason',
    stringKey: 'priceDiscountReason',
  },
  MODIFY_PRICE: {
    description: 'Text needed in various price mod messages',
    primaryValue: 'Modify Price',
    stringKey: 'modify',
  },
  BACK: {
    stringKey: 'back',
    primaryValue: 'Back',
    description: 'label on the returns wizard for "Back"',
  },
  NEXT: {
    stringKey: 'next',
    primaryValue: 'Next',
    description: 'label on the returns wizard for "Next"',
  },
  QUANTITY: {
    description: 'label on the Price Mod Dialog for "Quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  REASON_UNAVAILABLE: {
    description: 'error if reason code service unavailable',
    primaryValue: 'Reason codes unavailable',
    stringKey: 'reasonUnavailable',
  },
  CLOSE: {
    stringKey: 'close',
    primaryValue: 'Close',
    description: 'label to close Price Mod Return Dialog',
  },
  RETRY: {
    stringKey: 'retry',
    primaryValue: 'Retry',
    description: 'label to retry submission of price mod form',
  },
  PRICE_MOD_INFO: {
    description: 'label on the Modify Price dialog to enter price mod details',
    stringKey: 'priceModInfo',
    primaryValue: 'Modification (per unit)',
  },
  NEW_PRICE: {
    stringKey: 'newPrice',
    primaryValue: 'New Price',
    description: 'label for new price in step2 of modify price',
  },
  ERROR: {
    description: 'label for alerting the user that an action was unsuccessful',
    primaryValue: 'unsuccessful. Please try again!',
    stringKey: 'dialogError',
  },
  SUCCESS: {
    description: 'label for alerting the user that an action was successful',
    primaryValue: 'successful!',
    stringKey: 'dialogSuccess',
  },
  CONSUMER_RETURN_REASON: {
    description: 'label to show consumer reason for returns',
    primaryValue: 'Consumer Return Reason',
    stringKey: 'consumerReturnReason',
  },
  SIZE: {
    description: 'label on the size',
    primaryValue: 'Size',
    stringKey: 'size',
  },
  PRICE_VALIDATION_ERROR: {
    description: 'error message for invalid price',
    primaryValue: 'Not a valid discount amount',
    stringKey: 'priceValidationError',
  },
  SUBMIT_MODIFY_PRICE: {
    description: 'label on the submit modify price button',
    primaryValue: 'Submit Modify Price',
    stringKey: 'submitModifyPrice',
  },
  ARIA_DISCOUNT_PRICE: {
    description: 'aria label for discount price text unput box',
    primaryValue: 'discount price',
    stringKey: 'discountPrice',
  },
  PERCENT_OFF: {
    description: 'label on the discount option percent off',
    primaryValue: 'Percent Off',
    stringKey: 'percentOff',
  },
  AMOUNT_OFF: {
    description: 'label on the discount option amount off',
    primaryValue: 'Amount off',
    stringKey: 'amountOff',
  },
  EXACT_PRICE: {
    description: 'label on the discount option exact price',
    primaryValue: 'Exact Price',
    stringKey: 'exactPrice',
  },
  MODIFY_PRICE_TIME_OUT_ERROR_MESSAGE: {
    stringKey: 'modifyPriceTimeoutErrorMessage',
    primaryValue:
      'Price Modification is still pending.Please return to SwooshDesk and check back after some time',
    description: 'Error message when price modification call times out',
  },
  WITH_OUT_TAX: {
    description: 'text next to price in pricy mod step2',
    primaryValue: 'not including tax',
    stringKey: 'taxText',
  },
};

export default translations;
