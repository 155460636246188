import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import StyleLink from '../../content/shared/itemLink';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AssetsContext } from '../../../../store/contexts/assetsContext';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { Query } from 'react-apollo';
import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { getNewUnitPrice, getUnitPrice } from '../../../../utils/price';
import translations from './modifyPrice.i18n.js';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import {
  ReasonCodeTypes,
  ReasonCodeAppId,
  DiscountTypes,
} from '../../../../constants/dialog.const';
import useMemoTranslations from '../../../../hooks/useMemoTranslations';
import { step2SharedStyles } from '../sharedStyles';
import clsx from 'clsx';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import { appropriateLanguage } from '../../../../utils/language';
import { determinePriceModEligibility } from '../../../../utils/dialog';

/**
 * For a given reasonId and a given array of ReasonCodes,
 * returns the description of the ReasonCode that has the given id.
 *
 * @param (String|Number} id – the id which we're searching for
 * @param {Array} reasonCodes – the reason codes in which to search
 */
export const findReasonTextById = (id, reasonCodes) => {
  const reason = reasonCodes.find((element) => element.id === id);
  return reason && reason.description;
};

export default function Step2() {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [assets] = useContext(AssetsContext);
  const [i18State] = useContext(I18nContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { setDiscountReason, setDiscountType, setLineDiscountValue } = dialogActions;

  const {
    QUANTITY,
    PRICE_DISCOUNT_REASON,
    REASON_UNAVAILABLE,
    PRICE_MOD_INFO,
    NEW_PRICE,
    PERCENT_OFF,
    AMOUNT_OFF,
    EXACT_PRICE,
    REASON_CODES_LOADING,
    PRICE_VALIDATION_ERROR,
    SIZE,
    ARIA_DISCOUNT_PRICE,
    WITH_OUT_TAX,
  } = useMemoTranslations(translations);
  const { selectedLines } = dialogState;

  // pull the currency code from the details, for formatting purposes
  const { currency, orderLines } = orderDetail;

  // Dispatch the default discount type to state
  useEffect(() => {
    Object.values(selectedLines).map((line) => {
      if (!line.discountType) {
        dialogDispatch(setDiscountType(line, DiscountTypes.PERCENT_OFF));
      }
      return null;
    });
  }, []);

  /**
   * Display price after discount based on discount value and type
   * Show original price if the discount entered fails price validation or
   * discount value is not entered
   * @param {*} line - selected line
   */
  const displayNewPrice = (line) => {
    if (line.linePriceValidationError || !line.discountValue) {
      return getUnitPrice(line);
    } else {
      return getNewUnitPrice(line);
    }
  };

  return (
    <>
      {Object.values(selectedLines).map((line, i) => {
        const sumSyntheticAndRealQuantity = orderLines.reduce((accu, orderLine) => {
          // grab the eligible quantity for the modification
          const shouldAddQuantity =
            line.lineNumber === orderLine.lineNumber && determinePriceModEligibility(orderLine);
          return accu + (shouldAddQuantity ? orderLine.quantity : 0);
        }, 0);
        // -1 is the code for the default, unselectable reason
        const reason = line.discountReason || { code: '-1' };
        return (
          <Card key={i} className={clsx(classes.itemDetailsCard)}>
            <CardMedia
              className={classes.productThumb}
              image={getProductImageFromOrderLine(line, assets)}
              title={line.itemDescription}
            />
            <div className={classes.itemDetails}>
              <CardContent className={classes.itemDetailsContent}>
                <Typography variant='body1'>
                  <StyleLink orderDetail={orderDetail} line={line} />
                </Typography>
                <Typography noWrap variant='body1'>
                  {line.item && line.item.itemDescription}
                </Typography>
                <Typography variant='body1'>{line.colorDescription}</Typography>
                <Typography variant='body1'>
                  {SIZE} {line.displaySize}
                </Typography>
                <Typography variant='body1'>
                  <FormattedCurrency amount={getUnitPrice(line)} currency={currency} />
                  &nbsp;({WITH_OUT_TAX})
                </Typography>
                <Typography variant='body1' data-testid={`modify-price-quantity-${0}`}>
                  {QUANTITY}: {sumSyntheticAndRealQuantity || ''}
                </Typography>
                <div className={classes.discountSection}>
                  <label className={classes.discountLabel}>
                    {PRICE_MOD_INFO}:
                    <TextField
                      type='number'
                      role='textbox'
                      error={Boolean(line.linePriceValidationError)}
                      helperText={Boolean(line.linePriceValidationError) && PRICE_VALIDATION_ERROR}
                      value={line.discountValue || ''}
                      id={`discount_${i}`}
                      aria-label={ARIA_DISCOUNT_PRICE} // discount price
                      className={classes.discount}
                      variant='standard'
                      data-testid={`discount-value-${i}`}
                      onChange={(event) => {
                        dialogDispatch(setLineDiscountValue(line, Math.max(event.target.value, 0)));
                      }}
                    />
                  </label>
                </div>
                <FormControl className={classes.discountType} component='fieldset'>
                  <RadioGroup
                    row
                    value={line.discountType || DiscountTypes.PERCENT_OFF}
                    onChange={(event) => {
                      dialogDispatch(setDiscountType(line, event.target.value));
                    }}>
                    <FormControlLabel
                      value={DiscountTypes.PERCENT_OFF}
                      control={<Radio />}
                      label={PERCENT_OFF}
                      data-testid={`percent-off-${i}`}
                    />
                    <FormControlLabel
                      value={DiscountTypes.AMOUNT_OFF}
                      control={<Radio />}
                      label={AMOUNT_OFF}
                      data-testid={`amount-off-${i}`}
                    />
                    <FormControlLabel
                      value={DiscountTypes.EXACT_PRICE}
                      control={<Radio />}
                      label={EXACT_PRICE}
                      data-testid={`exact-price-${i}`}
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant='body1' data-testid={`new-price-${i}`}>
                  {NEW_PRICE} :{' '}
                  <FormattedCurrency amount={displayNewPrice(line)} currency={currency} />
                  &nbsp;({WITH_OUT_TAX})
                </Typography>
                <Query
                  query={REASON_CODES_QUERY}
                  variables={{
                    appId: ReasonCodeAppId,
                    omsRegionReference: orderDetail.omsRegionReference,
                    type: line.discountReasonCodeType || ReasonCodeTypes.PRICE_ADJUSTMENT,
                    language: appropriateLanguage(i18State.language),
                  }}>
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <CircularProgress aria-label={REASON_CODES_LOADING} title='progress-bar' />
                      );
                    if (error) return <Alert severity='warning'>{error.message}</Alert>;
                    if (data.reasonCodesV2 && data.reasonCodesV2.reasons) {
                      const reasonCodes = data.reasonCodesV2.reasons;
                      return (
                        <FormControl>
                          <Select
                            className={classes.reasonCodeSelect}
                            value={reason.code || -1}
                            name={line.lineNumber.toString()}
                            data-testid={`select-price-discount-reason-${i}`}
                            onChange={(event) => {
                              // save both the reason code and the reason description
                              const selectedReason = reasonCodes.find(
                                (reason) => reason.code === event.target.value
                              );
                              dialogDispatch(
                                setDiscountReason(line, {
                                  id: selectedReason.id,
                                  code: selectedReason.code,
                                  text: selectedReason.description,
                                })
                              );
                            }}>
                            <MenuItem value='-1' disabled>
                              {PRICE_DISCOUNT_REASON}
                            </MenuItem>
                            {reasonCodes.map((reason, j) => (
                              <MenuItem
                                key={j}
                                value={reason.code}
                                name={reason.fault}
                                data-testid={`price-discount-reason-${j}`}>
                                {reason.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    } else {
                      return <Alert severity='warning'>{REASON_UNAVAILABLE}</Alert>;
                    }
                  }}
                </Query>
              </CardContent>
            </div>
          </Card>
        );
      })}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step2SharedStyles(theme),
  reasonCodeContainer: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  reasonCodeSelect: {
    minWidth: 300,
    maxWidth: 300,
  },
  discountType: {
    display: 'block',
    minWidth: 500,
  },
  discount: {
    'marginLeft': theme.spacing(1),
    'maxWidth': 300,
    '& .MuiInputBase-root': {
      height: '1.2rem',
    },
  },
  discountSection: {
    display: 'flex',
  },
  discountLabel: {
    fontSize: '16px',
  },
}));
