const OtherCountries = [
  {
    name: 'China',
    label: 'China',
    abbreviation: 'CN',
    alpha3Code: 'CHN',
  },
  {
    name: 'Japan',
    label: 'Japan',
    abbreviation: 'JP',
    alpha3Code: 'JPN',
  },
  {
    name: 'United States of America',
    label: 'United States of America',
    abbreviation: 'US',
    alpha3Code: 'USA',
  },
  {
    name: 'Korea',
    label: 'Korea',
    abbreviation: 'KR',
    alpha3Code: 'KOR',
  },
];

export default OtherCountries;
