// dictionary to transform capitalcase paymentTypes to titlecase
export const paymentTypes = {
  ALIPAY: 'Alipay',
  ASTROPAY: 'AstroPay',
  BILLPAY: 'BillPay',
  CASH: 'Cash',
  CHECK: 'Check',
  COD: 'Cash on Delivery',
  CONV_STORE: 'Convenience Store',
  CREDIT_CARD: 'Credit Card',
  CUSTOMER_ACCOUNT: 'Customer Account',
  CUSTOMER_OFBT: 'Customer Offline Bank Transfer',
  DEBIT_CARD: 'Debit Card',
  GIFT_CERTIFICATE: 'Gift Certificate',
  IYZICO: 'Iyzico',
  KLARNA: 'Klarna',
  MALL_CERTIFICATE: 'Mall Certificate',
  OFFLINE_BANK: 'Offline Bank Transfer',
  OTHER_CREDIT: 'Other Credit',
  OTHER_DEBIT: 'Other Debit',
  PAYPAL: 'PayPal',
  POS_PURCHASE: 'Point of Sale',
  PROMO_VOUCHER: 'Voucher',
  QIWI: 'Qiwi',
  REALTIME_BANK: 'Realtime Bank Transfer',
  RFO: 'Request for Offer',
  SAFETYPAY: 'SafetyPay',
  SOFORT: 'SOFORT',
  STORE_CREDIT: 'Store Credit',
  STORE_GIFTCERT: 'Store Gift Certificate',
  TENPAY: 'TenPay',
  TRAVELERS_CHECK: "Traveler's Check",
  UNIONPAY: 'UnionPay',
  WECHAT: 'WeChat',
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  VOUCHER: 'Voucher',
};

/**
 * A handler function to proxy parameter lookup on our dictionary.
 */
const handler = {
  get: function(target, name) {
    return target.hasOwnProperty(name)
      ? // if the value exists in the dictionary, return it
        target[name]
      : // else, if the key is truthy, return that
        name || 'Unknown';
  },
};

// RegEx for Credit Card Number Validation
// Sourced from https://www.w3schools.blog/credit-card-validation-javascript-js
export const validCreditCardShapes = {
  // Starts with a 51/52/53/54/55 and a total length of 16.
  masterCardRegEx: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  // Starts with a 34 or 37 and has a total length of 15.
  americanExpressRegEx: /^3[47][0-9]{13}$/,
  // Starts with a 4 and has a total length of either 13 or 16.
  visaRegEx: /^4[0-9]{12}(?:[0-9]{3})?$/,
  discoverRegEx: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
};

// A proxied version of the Payment Types dictionary, allowing us to provide a default value.
export const PaymentTypesDict = new Proxy(paymentTypes, handler);

export const suspendedPaymentRefs = ['Y', 'B'];

export const paymentServiceMethods = {
  CREDIT_CARD: 'CreditCard',
  PAYPAL: 'Paypal',
  GIFT_CARD: 'GiftCard',
  COD: 'COD',
  SOFORT: 'Sofort',
  KLARNA: 'Klarna',
  ALIPAY: 'Alipay',
  WECHAT: 'WeChat',
  UNIONPAY: 'UnionPay',
  OFBT: 'offlineBank',
  KONBINI: 'Konbini',
  IDEAL: 'iDeal',
  DOTPAY: 'Dotpay',
  DIRECT_DEBIT: 'DirectDebit',
  PAYCO: 'Payco',
  BANK_TRANSFER: 'BankTransfer',
  NAVERPAY: 'NaverPay',
  KAKAOPAY: 'KakaoPay',
};

export const paymentServiceMethodLabels = {
  CreditCard: 'Credit Card',
  Paypal: 'Paypal',
  GiftCard: 'Gift Card',
  COD: 'Cash on Delivery',
  Sofort: 'Sofort',
  Klarna: 'Klarna',
  Alipay: 'Alipay',
  WeChat: 'WeChat',
  UnionPay: 'UnionPay',
  OFBT: 'Customer Offline Bank Transfer',
  Konbini: 'Konbini',
  iDeal: 'iDeal',
  Dotpay: 'Dotpay',
  DirectDebit: 'Direct Debit',
  Payco: 'Payco',
  BankTransfer: 'Bank Transfer',
  NAVERPAY: 'Naver Pay',
  KakaoPay: 'KakaoPay',
};

export const storePaymentServiceMethodslabels = {
  Cash: 'Cash',
  CreditDebit: 'Credit/Debit Card',
  MallCertificate: 'Mall Certificate',
  StoreAccount: 'Store Account',
  OnlinePayment: 'Online Payment',
  Voucher: 'Voucher',
  Check: 'Check',
  ForeignCurrency: 'Foreign Currency',
  TravelersCheck: "Traveler's Check",
  GiftCard: 'Gift Card',
  StoreCredit: 'Store Credit',
  GiftCertificate: 'Gift Certificate',
  ValueCertificate: 'Value Certificate',
};

export const PaymentServiceMethodsDict = new Proxy(paymentServiceMethods, handler);
