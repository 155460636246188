// enum of possible orderDetailVersions
export const OrderDetailVersion = {
  V1: 'v1',
  V2: 'v2',
};

// enum of possible userTypes
export const UserType = {
  BRAND: 'nike:plus',
  SWOOSH: 'nike:swoosh',
  GUEST: 'nike:guest',
};

export const BopisOrderStatus = {
  ReadyForPickUp: 'ReadyForPickUp',
  PartiallyReadyForPickUp: 'Partially ReadyForPickUp',
  PickedUp: 'PickedUp',
  PartiallyPickedUp: 'Partially PickedUp',
};

export const ReturnMethods = {
  carrierDropOff: 'CARRIER_DROP_OFF',
  paperless: 'PAPERLESS',
  pickUp: 'PICK_UP',
  storeDropOff: 'STORE_DROP_OFF',
  receivedAtWarehouse: 'RECEIVED_AT_WAREHOUSE',
};

export const orderLineTypes = [
  {
    name: 'Inline',
    value: 'INLINE',
  },
  {
    name: 'Nike By You',
    value: 'NIKEID',
  },
  {
    name: 'Digital Gift Card',
    value: 'EGC',
  },
  {
    name: 'Physical Gift Card',
    value: 'GC',
  },
  {
    name: 'Voucher',
    value: 'VOUCHER',
  },
];

export const orderTypesList = [
  {
    name: 'Sales Order',
    value: 'SALES_ORDER',
  },
  {
    name: 'Return Order',
    value: 'RETURN_ORDER',
  },
  {
    name: 'Transfer Order',
    value: 'TRANSFER_ORDER',
  },
  {
    name: 'Reserve Order',
    value: 'RESERVE_ORDER',
  },
  {
    name: 'Purchase Order',
    value: 'PURCHASE_ORDER',
  },
];

export const orderTypes = {
  SALES: 'SALES_ORDER',
  RETURN: 'RETURN_ORDER',
  TRANSFER: 'TRANSFER_ORDER',
  RESERVE: 'RESERVE_ORDER',
  PURCHASE: 'PURCHASE_ORDER',
};

export const orderClassifications = {
  REFUND: 'REFUND',
  STORE: 'STORE',
};

// copied from https://confluence.nike.com/display/MOM/Order+Status+Mapping+for+Consumers
export const orderStatuses = [
  'Created',
  'CREATED',
  'Cancelled',
  'Shipped',
  'Delivered',
  'Return Created',
  'Inspected And Invoiced',
  // ^ keep the helpful ones at the top of the list
  'Draft Order Created',
  'Draft Order Reserved Awaiting Acceptance',
  'Draft Order Reserved',
  'Authorized',
  'Not Authorized',
  'Pending Completion',
  'Origin Scan',
  'In Transit',
  'Acknowledged',
  'Work Order Cancelled',
  'Delivered to Return Center',
  'Return Invoiced',
  'Early Refund',
  'Returned At Store',
  'Inspection Escalate',
  'Factory Processing',
  'Factory Completed',
  'Carried',
  'Factory Delayed',
  'Reserved Awaiting Acceptance',
  'Reserved',
  'Being Negotiated',
  'Accepted',
  'Rescheduling',
  'Pending Cancel',
  'BOReview',
  'Unscheduled',
  'Backordered From Node',
  'Scheduled',
  'Awaiting Chained Order Creation',
  'Awaiting Procurement Purchase Order Creation',
  'Awaiting Procurement Transfer Order Creation',
  'Chained Order Created',
  'PO Sent To Pulse',
  'PO Released',
  'Released For SO Create',
  'DSV Acknowledged',
  'Released To Third Party',
  'Received By Partner',
  'Confirmed By Partner',
  'Order Processing',
  'Order Completed',
  'Order Delayed',
  'PO Acknowledged',
  'Released To FC',
  'PO Sent To Warehouse',
  'PO Warehouse Acknowledged',
  'PO Scheduled For Pick',
  'Order Received',
  'PO Awaiting Shipment',
  'Order Processed',
  'DSV Acknowledged',
  'Sent For SO Create',
  'DSV Acknowledged',
  'Procurement Purchase Order Created',
  'Procurement Purchase Order Shipped',
  'Work Order Created',
  'Work Order Completed',
  'Procurement Transfer Order Created',
  'Procurement Transfer Order Shipped',
  'Released',
  'Awaiting Shipment Consolidation',
  'Awaiting WMS Interface',
  'Published To WMS',
  'Acknowledged',
  'Sent To Warehouse',
  'Received By Partner',
  'Confirmed By Partner',
  'ReadyForPickUp',
  'PickedUp',
  'Pending Release To SAP',
  'Scheduled For Pick',
  'Awaiting Shipment',
  'Order Received',
  'Order Processed',
  'DSV Acknowledged',
  'Sent To Node',
  'Included In Shipment',
  'Awaiting Invoice',
  'Return Request Created',
  'Return Received',
  'Return In Progress',
  'Return Processed',
  'Return Denied',
  'Origin Scan',
  'Customs Cleared',
  'Delivered To Store',
  'Ready For Pickup',
  'Completed',
  'Customer Picked Up',
  'Shipment Delayed',
  'Picked Up',
  'Manifest Scan',
  'Abandoned',
  'In Transit',
  'Delivery Attempt 1',
  'Delivery Attempt 2',
  'Delivery Attempt 3',
  'Notionally Delivered',
  'Order Delivered',
  'Order Completed',
  'Unable To Deliver',
  'Returned to DC',
  'Refused Shipment',
  'Returned To Carrier',
  'Invoiced',
  'Received',
  'Return Invoiced',
  'Inspected And Invoiced',
  'Awaiting Exchange Order Creation',
  'Exchange Order Created',
  'Received at Return Center',
  'Inspection Passed',
  'Inspection Escalate',
  'Inspection Denied',
  'CSR Refund',
  'Held',
  'Shipment Delayed',
  'Void',
  'Post Voided',
  'Order Completed',
  'Delivered to Return Center',
  'UNRESERVED',
  'Submitted',
  'Ready for Pickup',
  'Canceled',
  'Claimed',
  'Complete',
  'Cancel Request',
];

export const LineItemInstructionType = {
  GIFT_MESSAGE: 'GIFTMSG',
};

export const BomPidTextFieldMatcher = /- abc/i;

export const BomColorType = 'color';

export const displaySDDRegions = ['NIKEJP'];
