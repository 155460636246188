import sharedTranslations from './shared.i18n';

const translations = {
  ARIA_RETURN_LABEL: {
    description: 'aria label for return label address',
    primaryValue: 'return label address',
    stringKey: 'ariaReturnLabel',
  },
  BACK: sharedTranslations.BACK,
  CREATE_RETURN: sharedTranslations.CREATE_RETURN,
  ERROR: sharedTranslations.ERROR,
  SUCCESS: sharedTranslations.SUCCESS,
  BILLING_ADDRESS: {
    description: 'label on the returns wizard for "Billing Address"',
    primaryValue: 'Billing Address',
    stringKey: 'billingAddress',
  },
  EMAIL: {
    description: 'label on the returns wizard for "Email"',
    primaryValue: 'Email',
    stringKey: 'email',
  },
  GUEST_PREPAID_MESSAGE: {
    description: 'message for guest receiving prepaid label',
    primaryValue: 'Guest will receive courtesy prepaid label.',
    stringKey: 'guestPrepaidMessage',
  },
  MAIL: {
    description: 'label on the returns wizard for "Mail',
    primaryValue: 'Mail',
    stringKey: 'mail',
  },
  MILITARY_ADDRESS: {
    description: 'label on the returns wizard for "Military Address',
    primaryValue: 'Military Address',
    stringKey: 'militaryAddress',
  },
  NON_PREPAID_MESSAGE: {
    description: 'Message informing user that label will not be prepaid',
    primaryValue: 'Guest will receive a non prepaid label',
    stringKey: 'nonPrepaidMessage',
  },
  NON_PREPAID: {
    description: 'label on return for sending non-prepaid label',
    primaryValue: 'Non-Prepaid',
    stringKey: 'nonPrepaid',
  },
  RETURN_LABEL_EMAIL: {
    description: 'label informing user that return label will be sent via email',
    primaryValue: 'Return label will be sent by email.',
    stringKey: 'returnLabelSentByEmail',
  },
  RESEND_RETURN_LABEL: {
    description: 'label on the resend return label wizard for "Resend Return Label"',
    primaryValue: 'Resend Return Label',
    stringKey: 'resendReturnLabel',
  },
  RETURN_LABEL_SENT: {
    description: 'success message for resending a return label',
    primaryValue: 'Return Label Sent!',
    stringKey: 'returnLabelSent',
  },
  UPS: {
    description: 'label on the returns wizard for "UPS',
    primaryValue: 'UPS',
    stringKey: 'ups',
  },
  USPS: {
    description: 'label on the returns wizard for "USPS',
    primaryValue: 'USPS',
    stringKey: 'usps',
  },
  USPS_MESSAGE: {
    description: 'Message informing user that label will be sent via USPS',
    primaryValue: 'Label will be a prepaid USPS label',
    stringKey: 'uspsMessage',
  },
  PICKUP: {
    description: 'label on the returns wizard for "PICKUP"',
    primaryValue: 'PICKUP',
    stringKey: 'pickup',
  },
  PREPAID: {
    description: 'label on return for sending prepaid label',
    primaryValue: 'Prepaid',
    stringKey: 'prepaid',
  },
  NON_PICKUP: {
    description: 'label on the returns wizard for "NON_PICKUP"',
    primaryValue: 'NON_PICKUP',
    stringKey: 'nonPickup',
  },
  PREPAID_MESSAGE: {
    description: 'Message informing user that label will be prepaid.',
    primaryValue: 'Label will be prepaid.',
    stringKey: 'prepaidMessage',
  },
  DIGITAL_PREPAID_MESSAGE: {
    description: 'Message informing user that digital label will be prepaid.',
    primaryValue: 'Digital label will be prepaid.',
    stringKey: 'digitalPrepaidMessage',
  },
};

export default translations;
